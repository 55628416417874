import React, { useEffect, useState } from "react";
import { LoginRegisterPage } from "../components/organisms/LoginRegisterPage/LoginRegisterPage";
import { PageTitle, SEO } from "../modules/seo";

interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: {
    key: string;
    email?: string;
  };
}

const LoginPage = ({ location }: { location: ILocation }) => {
  const [newsletterMail, setNewsletterMail] = useState<string | undefined>();
  useEffect(() => {
    if (location?.state?.email) {
      setNewsletterMail(location?.state?.email);
    }
  }, [location]);
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: "0 5%",
      }}
    >
      <SEO title={PageTitle.Login} />
      <LoginRegisterPage email={newsletterMail} />
    </div>
  );
};

export default LoginPage;
