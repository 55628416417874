/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useEffect } from "react";
import classNames from "classnames";
import * as s from "./LoginForm.module.scss";
import { useState } from "react";
import FormProvider from "../../../forms/FormProvider";
import { useMutation } from "@apollo/client";
import { CUSTOMER_ACCESS_TOKEN_CREATE } from "../../../g/mutations/customer";
import { navigate } from "gatsby";
import { AUTH_TOKEN } from "../../../constants/authToken";
import { useDispatch } from "react-redux";
import { userConstants } from "../../../modules/auth/constants";
import { loginWithFacebook, loginWithGoogle } from "../../../libs/firebase";
import { AiOutlineGoogle } from "react-icons/ai";
import { CgFacebook } from "react-icons/cg";

const cn = classNames.bind(s);

const LoginForm = () => {
  const [loginData, setLoginData] = useState<TroopState.EndpointPayload>({});
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (data: TroopState.EndpointPayload) => {
    setLoginData(data);
  };

  const [login] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE, {
    variables: {
      input: {
        email: loginData.email,
        password: loginData.password,
      },
    },
    onCompleted: (data) => {
      if (data?.customerAccessTokenCreate?.customerAccessToken === null) {
        if (data?.customerAccessTokenCreate?.customerUserErrors.length)
          setError("The password or email is wrong.");
      }

      const user = data?.customerAccessTokenCreate?.customerAccessToken;
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: {
          user,
        },
      });
      if (user) {
        localStorage.setItem(AUTH_TOKEN, user.accessToken);
        navigate("/profile");
      }
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  useEffect(() => {
    if (loginData.email !== undefined && loginData.password !== undefined)
      login();
  }, [loginData]);

  const handleFacebookLogin = () => {
    loginWithFacebook(console.log, (res) => {
      if (res.email && res.firebaseId) {
        setLoginData({
          email: res.email,
          password: res.firebaseId,
        });
      }
    });
  };
  const handleGoogleLogin = () => {
    loginWithGoogle(console.log, (res) => {
      if (res.email && res.firebaseId) {
        setLoginData({
          email: res.email,
          password: res.firebaseId,
        });
      }
    });
  };

  return (
    <div className={cn(s.container)} style={{ zIndex: 3 }}>
      <div className={s.formwrapper}>
        <FormProvider
          formKey="login"
          formSubmit={handleSubmit}
          buttonText="Login"
          text="Forgot your password?"
          errorMessage={error}
        ></FormProvider>
      </div>
      <p className={s.loginText}>Or log in with</p>
      <div className={s.loginWrapper}>
        <div onClick={handleGoogleLogin} className={s.googleWrapper}>
          <div className={s.iconWrapper}>
            <AiOutlineGoogle fill="#ffffff" />
          </div>
          <div className={s.textWrapper}>Google</div>
        </div>
        {/* <div onClick={handleFacebookLogin} className={s.fbWrapper}>
          <div className={s.iconWrapper}>
            <CgFacebook fill="#fffff" />
          </div>
          <div className={s.textWrapper}>Facebook</div>
        </div> */}
      </div>
    </div>
  );
};

export { LoginForm };
