// extracted by mini-css-extract-plugin
export var container = "LoginRegisterPage-module--container--LZxjU";
export var formwrapper = "LoginRegisterPage-module--formwrapper--UTmyP";
export var newSectionWrapper = "LoginRegisterPage-module--new-section-wrapper--oXeaB";
export var or = "LoginRegisterPage-module--or--+Nwwq";
export var signin = "LoginRegisterPage-module--signin--ODrsT";
export var signingDiv = "LoginRegisterPage-module--signing-div--ub2-a";
export var signup = "LoginRegisterPage-module--signup--PNrpY";
export var span = "LoginRegisterPage-module--span--0-pBD";
export var textWrapper = "LoginRegisterPage-module--text-wrapper--0MWnE";
export var wrapper = "LoginRegisterPage-module--wrapper--4cmwg";