/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useEffect } from "react";
import classNames from "classnames";
import * as s from "./RegisterForm.module.scss";
import { useState } from "react";
import FormProvider from "../../../forms/FormProvider";
import { useMutation } from "@apollo/client";
import { AUTH_TOKEN } from "../../../constants/authToken";
import { navigate } from "gatsby";
import {
  CREATE_CUSTOMER_MUTATION,
  CUSTOMER_ACCESS_TOKEN_CREATE,
} from "../../../g/mutations/customer";
import { useDispatch } from "react-redux";
import { userConstants } from "../../../modules/auth/constants";
import { toast } from "react-toastify";
import { loginWithFacebook, loginWithGoogle } from "../../../libs/firebase";
import { AiOutlineGoogle } from "react-icons/ai";
import { CgFacebook } from "react-icons/cg";
import { TermsOfService } from "../../../constants/termsOfService";
import PopUp from "../PopUp/PopUp";

const cn = classNames.bind(s);

interface IRegisterForm {
  email?: string;
}

const RegisterForm = ({ email }: IRegisterForm) => {
  const [TOSOpen, setTOSOpen] = useState(false);
  const [agreedToTOS, setAgreedToTOS] = useState(false);
  const [registerData, setRegisterData] = useState<TroopState.EndpointPayload>(
    {}
  );
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const [signup] = useMutation(CREATE_CUSTOMER_MUTATION, {
    variables: {
      input: {
        email: registerData.email,
        password: registerData.password,
        firstName: registerData.firstName,
        lastName: registerData.lastName,
      },
    },
    onCompleted: (data) => {
      if (data?.customerCreate?.customer === null) {
        toast(data?.customerCreate?.customerUserErrors[0]?.message, {
          type: "error",
        });
        setError(data?.customerCreate?.customerUserErrors[0]?.message);
      } else {
        const user = data?.customerCreate?.customer;
        if (user) {
          toast("SUCCESS!", { type: "success" });
          dispatch({
            type: userConstants.REGISTER_SUCCESS,
            user: user,
          });
          createToken();
        }
      }
    },
  });

  const [createToken] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE, {
    variables: {
      input: {
        email: registerData.email,
        password: registerData.password,
      },
    },
    onCompleted: (data) => {
      const user = data?.customerAccessTokenCreate?.customerAccessToken;
      if (user) {
        localStorage.setItem(AUTH_TOKEN, user.accessToken);
        navigate("/profile");
      }
    },
  });

  const handleClick = async (userData: TroopState.EndpointPayload) => {
    if (!agreedToTOS)
      toast("You must agree to the terms of service!", { type: "error" });
    else setRegisterData(userData);
  };

  useEffect(() => {
    if (
      registerData.email !== undefined &&
      registerData.password !== undefined
    ) {
      signup();
    }
  }, [registerData]);

  const handleGoogleSignup = () => {
    loginWithGoogle(console.log, (res) => {
      if (res.email && res.firebaseId) {
        setRegisterData({
          email: res.email,
          firstName: res.given_name || "",
          lastName: res.family_name || "",
          password: res.firebaseId,
        });
      }
    });
  };
  const handleFacebookSignup = () => {
    loginWithFacebook(console.log, (res) => {
      if (res.email && res.firebaseId) {
        setRegisterData({
          email: res.email,
          firstName: res.first_name || "",
          lastName: res.last_name || "",
          password: res.firebaseId,
        });
      }
    });
  };

  return (
    <>
      <div className={cn(s.container)}>
        <div className={s.formwrapper}>
          <FormProvider
            formKey="register"
            formSubmit={handleClick}
            buttonText="Register"
            text="<span>I agree to Troop’s </span>terms of service"
            hasCheckbox={true}
            checked={agreedToTOS}
            setChecked={setAgreedToTOS}
            onTextClick={() => {
              setTOSOpen(true);
            }}
            initialValues={
              Object.assign(
                {},
                { email: email, acceptsMarketing: !!email }
              ) as TroopState.EndpointPayload
            }
            errorMessage={error}
          ></FormProvider>
        </div>

        <p className={s.loginText}>Or sign up with</p>
        <div className={s.registerWrapper}>
          <div onClick={handleGoogleSignup} className={s.googleWrapper}>
            <div className={s.iconWrapper}>
              <AiOutlineGoogle fill="#ffffff" />
            </div>
            <div className={s.textWrapper}>Google</div>
          </div>

          {/* <div onClick={handleFacebookSignup} className={s.fbWrapper}>
            <div className={s.iconWrapper}>
              <CgFacebook fill="#fffff" />
            </div>
            <div className={s.textWrapper}>Facebook</div>
          </div> */}
        </div>
      </div>
      <PopUp
        info={TermsOfService}
        visible={TOSOpen}
        handlePopUp={() => setTOSOpen(false)}
      />
    </>
  );
};

export { RegisterForm };
