/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React from "react";
import classNames from "classnames";
import * as s from "./LoginRegisterPage.module.scss";
import { useState } from "react";
import NewHereSection from "../../atoms/NewHereSection/NewHereSection";
import { LoginForm } from "../../molecules/LoginForm/LoginForm";
import { RegisterForm } from "../../molecules/RegisterForm/RegisterForm";

const cn = classNames.bind(s);

interface ILoginRegisterPage {
  email?: string;
}

const LoginRegisterPage = ({ email }: ILoginRegisterPage) => {
  const [signin, setSignin] = useState(true);
  const [signup, setSignup] = useState(false);

  const handleSignin = () => {
    setSignup(signin);
    setSignin(!signin);
  };

  const handleSignup = () => {
    setSignin(signup);
    setSignup(!signup);
  };

  return (
    <div className={cn(s.container)}>
      <div className={s.formwrapper}>
        <div className={s.wrapper}>
          <div className={s.textWrapper}>
            <p onClick={() => handleSignin()}>
              Sign <span className={s.span}>in</span>
            </p>
            <span className={s.or}> or </span>
            <p onClick={() => handleSignup()}>
              sign <span className={s.span}>up</span>
            </p>
          </div>

          <div className={s.signingDiv}>
            <div
              className={s.signin}
              onClick={() => handleSignin()}
              style={
                signin ? { background: "#16b76a" } : { background: "#ffffff" }
              }
            ></div>
            <div
              className={s.signup}
              onClick={() => handleSignup()}
              style={
                signup ? { background: "#16b76a" } : { background: "#ffffff" }
              }
            ></div>
          </div>
        </div>
        {signin ? <LoginForm /> : <RegisterForm email={email} />}
      </div>

      <div className={s.newSectionWrapper}>
        {signin ? (
          <NewHereSection descritpion="Explore our site, become a subscriber and earn additional benefits!" />
        ) : (
          <NewHereSection descritpion="Explore our site, become a subscriber and earn additional benefits!" />
        )}
      </div>
    </div>
  );
};

export { LoginRegisterPage };
