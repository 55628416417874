import Firebase from "gatsby-plugin-firebase";

const firebase = Firebase as any;

type FirebaseAuthCredentials = Partial<{
  email: string;
  family_name: string;
  firebaseId?: string;
  given_name: string;
  granted_scopes: string;
  id: string;
  isNewUser?: boolean;
  locale: string;
  name: string;
  picture: string;
  verified_email: true;
  customToken?: string;
}>;
type FirebaseFbAuthCredentials = {
  email: string;
  last_name: string;
  firebaseId: string;
  first_name: string;
  id: string;
  isNewUser?: boolean;
};

export const loginWithFacebook = async (
  showLoader: (loading: boolean) => void,
  callback?: (user: FirebaseFbAuthCredentials) => void
): Promise<void> => {
  const provider = new firebase.auth.FacebookAuthProvider();
  // console.log(provider);
  // provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  // provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  // provider.addScope("openid");

  // firebase.auth().signInWithRedirect(provider);
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      console.log("🚀 ~ file: firebase.ts ~ line 36 ~ .then ~ res", res);
      const { additionalUserInfo, user } = res;

      firebase
        .auth()
        .currentUser?.getIdToken()
        .then((token: string) => {
          console.log(
            "🚀 ~ file: firebase.ts ~ line 43 ~ .then ~ token",
            token
          );
          if (token) localStorage.setItem("token", token);
          if (callback)
            callback({
              ...additionalUserInfo?.profile,
              isNewUser: additionalUserInfo?.isNewUser,
              firebaseId: user?.uid,
            });
        });
    });
  showLoader(true);
};

export const loginWithGoogle = async (
  showLoader: (loading: boolean) => void,
  callback?: (user: FirebaseAuthCredentials) => void
): Promise<void> => {
  const provider = new firebase.auth.GoogleAuthProvider();

  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  provider.addScope("openid");

  // firebase.auth().signInWithRedirect(provider);
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      console.log("🚀 ~ file: firebase.ts ~ line 75 ~ .then ~ res", res);
      const { additionalUserInfo, user } = res;

      firebase
        .auth()
        .currentUser?.getIdToken()
        .then((token: string) => {
          console.log(
            "🚀 ~ file: firebase.ts ~ line 43 ~ .then ~ token",
            token
          );
          if (token) localStorage.setItem("token", token);
          if (callback)
            callback({
              ...additionalUserInfo?.profile,
              isNewUser: additionalUserInfo?.isNewUser,
              firebaseId: user?.uid,
            });
        });
    });
  showLoader(true);
};
